<template>
  <div id="docs-row">
    <v-card>
      <template v-if="deleteOverlay">
        <v-overlay :value="deleteOverlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <v-card-title class="white--text">
        <div class="text-center">
          <v-menu open-on-hover bottom offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                color="white accent-2"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <v-list dense class="pt-0 pb-0">
              <v-list-item @click="addNewDoc()">
                <v-list-item-title>Add New Document</v-list-item-title>
              </v-list-item>
              <v-list-item @click="docAssociation(null, 'create')">
                <v-list-item-title>Associate Document</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        LON {{ id }}'s Documents
        <v-spacer></v-spacer>

        <v-select
          v-model="docsSearchFilter"
          class="mt-6 pr-2 searchFilter"
          dense
          :items="['Active', 'Expired', 'All']"
          label="Filter By"
          @change="updateDocHeaders"
        ></v-select>

        <v-text-field
          class="pt-0 mt-0"
          v-model="docsSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        >
        </v-text-field>
      </v-card-title>

      <v-data-table
        loading-text="Loading"
        :custom-filter="filterByStatus"
        :fixed-header="true"
        :headers="docHeaders"
        height="550"
        :items="filteredDocs"
        :item-class="isExpired"
        :search="docsSearch"
        :footer-props="{ 'items-per-page-options': [10, 30, 50, -1] }"
        dense
      >
        <template v-slot:item.DocId="{ item }">
          <a
            :href="
              `${directoryEnvUrl}/directory/document/?ac=edit&SUBMIT=EDIT&DocId=${item.DocId}&LineOrderNumber=${item.LineOrderNumber}`
            "
            target="_blank"
          >
            {{ item.DocId }}
          </a>
        </template>

        <template v-slot:item.OrgName="{ item }">
          <template v-if="item.CurrentStatus == 'Active'">
            <a
              :href="
                `${directoryEnvUrl}/directory/organization/?SUBMIT=EDIT&OrgId=${item.OrgId}`
              "
              target="_blank"
            >
              {{ item.OrgName }}
            </a>
          </template>
          <template v-else>
            {{ item.OrgName }}
          </template>
        </template>

        <template v-slot:item.DocTitle="{ item }">
          {{
            item.DocTitle.length > docTitleLimit
              ? item.DocTitle.slice(0, docTitleLimit) + "..."
              : item.DocTitle
          }}
        </template>

        <template v-slot:item.adjustDates="{ item }">
          <v-btn
            v-if="item.CurrentStatus == 'Active'"
            icon
            @keydown.enter.prevent
            @click.native="docAssociation(item, 'edit')"
          >
            <v-icon>mdi-calendar-edit</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.remove="{ item }">
          <v-btn
            style="text-decoration: none"
            icon
            @keydown.enter.prevent
            @click.native="featureDelete(item, item.CurrentStatus == 'Active' ? 'active' : 'expired')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!--Adjust Dates/Associate Docs Dialog-->
    <template>
      <v-row justify="center" class="mt-0 mb-0 ml-0 mr-0">
        <v-dialog v-model="docAssociationDialog" persistent :max-width="1200">
          <v-form ref="form" v-model="valid">
            <v-card v-if="associatedDoc">
              <v-card-title>
                <span class="headline">{{editAssociation ? 'Adjust Dates' : 'Add Association'}}</span>
              </v-card-title>
              <v-card-subtitle>
                <span v-if="associatedDoc.DocTitle">Document: {{associatedDoc.DocTitle}}</span>
              </v-card-subtitle>
              <v-card-text v-if="associatedDocError" v-html="associatedDocError" class="red--text pb-0"></v-card-text>
              <v-card-text>
                <template v-if="overlay">
                  <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>
                </template>
                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0" justify="center">

                    <v-col>
                      <v-autocomplete
                        v-if="!editAssociation"
                        v-model="associatedDoc.DocId"
                        :items="items"
                        :search-input.sync="search"
                        item-text="DocId"
                        item-value="DocId"
                        label="Document Id *"
                        :loading="isLoading"
                        :filter="associateDocsFilter"
                        no-data-text="No Results Found"
                        class="associatedAutoComplete"
                        :readonly="this.editAssociation"
                        :rules="[(v) => !!v || 'Required']"
                      >
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-content>
                              <v-list-item-title :class="{ strike: data.item.disabled }" v-html="data.item.DocId + ' - ' + data.item.DocTitle"></v-list-item-title>
                              <v-list-item-subtitle class="red--text add-pointer-event" v-if="data.item.error.length > 0" v-html="data.item.error"></v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>

                    <v-text-field
                      v-if="editAssociation"
                      v-model="associatedDoc.DocId"
                      label="Document Id *"
                      readonly
                      required
                      :rules="[(v) => !!v || 'Required']"
                    ></v-text-field>
                    </v-col>

                    <v-col>
                      <!--Start Date Dialog-->
                      <v-dialog
                        ref="StartDateDialog"
                        v-model="startDateModal"
                        :return-value.sync="associatedDoc.StartDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="associatedDoc.StartDate"
                            label="Start Date *"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                            :rules="[(v) => !!v || 'Required']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            :min="origLO.StartDate"
                            :max="origLO.EndDate"
                            scrollable
                            v-model="associatedDoc.StartDate">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="startDateModal = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.StartDateDialog.save(associatedDoc.StartDate)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                    <v-col>
                      <!--End Date Dialog-->
                      <v-dialog
                        ref="EndDateDialog"
                        v-model="endDateModal"
                        :return-value.sync="associatedDoc.EndDate"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="associatedDoc.EndDate"
                            label="End Date *"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                            :rules="[(v) => !!v || 'Required']"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                            :min="origLO.StartDate"
                            :max="origLO.EndDate"
                            scrollable
                            v-model="associatedDoc.EndDate">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="endDateModal = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.EndDateDialog.save(associatedDoc.EndDate)"
                            >OK</v-btn
                          >
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="saving"
                  color="blue darken-1"
                  text
                  @click="close()"
                >
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  :disabled="!valid || saving"
                  text
                  @click="
                    saveDocAssociation()
                  "
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["allDocs", "getAllDocs", "id", "LO", "origLO"],

  data: () => ({
    associatedDoc: null,
    associatedDocOrig: null,
    associatedDocDefault: {
      StartDate: null,
      EndDate: null
    },
    associatedDocError: null,
    deleteOverlay: false,
    docAssociationDialog: false,
    docHeaders: [
      { text: "Doc ID", value: "DocId", width: "5%" },
      { text: "Organization", value: "OrgName", width: "10%" },
      { text: "Title", value: "DocTitle", width: "20%" },
      { text: "Type", value: "DocTypeDesc", width: "10%" },
      { text: "Start Date", value: "StartDate", width: "10%" },
      { text: "End Date", value: "EndDate", width: "10%" },
      { text: "Primary Topic", value: "PriTopic", width: "13%" },
      { text: "URL", value: "DocURL", width: "15%" },
      { text: "Status", value: "StatusName", width: "5%" },
      { text: "Adjust Dates", value: "adjustDates", width: "1%" },
      { text: "", value: "remove", width: "1%", align: "center", sortable: false },
      { text: "Feature ID", value: "FeatureId", align: " d-none" }, //leading space for d-none is needed to keep filter but hide column
      { text: "CurrentStatus", value: "CurrentStatus", align: " d-none" } //leading space for d-none is needed to keep filter but hide column
      //{ text: '', value: '', align: '' },
    ],
    docs: [],
    docsSearch: "",
    docsSearchFilter: "Active",
    docTitleLimit: 40,
    editAssociation: false,
    endDateModal: false,
    isLoading: false,
    overlay: false,
    primaryTopicHeader: [{ text: "Primary Topic", value: "PriTopic", width: "13%" }],
    registrationHeader: [{ text: "Registration", value: "", width: "13%" }],
    request: undefined,
    saving: false,
    search: null,
    startDateModal: false,
    valid: false,
  }),
  computed: {
    directoryEnvUrl() {
      return this.$store.getters["auth/getApiHost"]["ups_admin"];
    },

    documentUrl(){
      return `${this.directoryEnvUrl}/directory/document/?dashboardviewid=0&TABLENAME=itpapers.Document&FILTER[DocStatusId]=1&itpapers_Document-LIMIT=20&itpapers_Document-PAGING=1&SUBMIT=EDIT&DocId=`;
    },

    filteredDocs() {
      if (this.docsSearchFilter != "All")
        return this.allDocs.filter((doc) => doc.CurrentStatus == this.docsSearchFilter);
      else return this.allDocs;
    },

    items() {
      return this.docs.map((doc) => {
        doc.error = "";
        //docs are the docs that exist in itpapers.Feature
        if(this.associatedDoc.StartDate <= doc.StartDate && this.associatedDoc.EndDate >= doc.EndDate){
          doc.error = `StartDate conflicts with Line Order Number <a href="${this.LOURL + doc.LineOrderNumber}" target="_blank">${doc.LineOrderNumber}</a><br>
                       EndDate conflicts with Line Order Number <a href="${this.LOURL + doc.LineOrderNumber}" target="_blank">${doc.LineOrderNumber}</a>`;
        }
        else {
          if(this.associatedDoc.StartDate >= doc.StartDate && this.associatedDoc.StartDate <= doc.EndDate){
            doc.error += `StartDate conflicts with Line Order Number <a href="${this.LOURL + doc.LineOrderNumber}" target="_blank">${doc.LineOrderNumber}</a><br>`;
          }
          if(this.associatedDoc.EndDate >= doc.StartDate && this.associatedDoc.EndDate <= doc.EndDate){
            doc.error += `EndDate conflicts with Line Order Number <a href="${this.LOURL + doc.LineOrderNumber}" target="_blank">${doc.LineOrderNumber}</a>`;
          }
        }

        //If Evergreen LO, make sure Document is active
        if(this.LO.Evergreen){
          if(doc.DocStatusId != 1){
            doc.error += `Document <a href="${this.documentUrl + doc.DocId}" target="_blank">${doc.DocId}</a> must be active to add to Evergreen Campaign<br>`;
          }
        }

        doc.disabled = doc.error.length > 0 ? true : false;

        return Object.assign({}, doc, {});
      });
    },

    LOURL() {
      return `${this.directoryEnvUrl}/directory/lineorder/?SUBMIT=EDIT&LineOrderNumber=`;
    }
  },

  methods: {
    addNewDoc() {
      alert("Add New Document");
    },

    associateDocsFilter(item, queryText,itemText){
      //if searching docID
      if(queryText.match(/^-?\d+$/)){
        let regex = new RegExp(queryText + ".*")
        if(itemText.match(regex)) return true;
      }
      //if searching for docTitle
      else
        return item.DocTitle.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },

    close(){
      if(this.editAssociation){
        this.resetAssociatedDates();

        this.editAssociation = false;
        this.associatedDocOrig = null;
      }

      this.docAssociationDialog = false;
      this.associatedDoc = null;
      this.associatedDocError = null;
    },

    //action: create || edit
    docAssociation(doc = null, action) {
      if(!action) return;

      //check if new or update association
      if(doc != null){
        this.associatedDoc = doc;
        this.associatedDocOrig = JSON.parse(JSON.stringify(this.associatedDoc));
      } else
          this.associatedDoc = JSON.parse(JSON.stringify(this.associatedDocDefault));

      if(action == "create"){
        this.associatedDoc.EndDate = this.origLO.EndDate;
        this.associatedDoc.StartDate = this.origLO.AssociationStartDate;
        this.associatedDoc.CollectLeads = this.origLO.CollectLeads;
        this.associatedDoc.LineOrderNumber = this.origLO.LineOrderNumber;
      }

      if(action == "edit"){
        this.editAssociation = true;
      }

      this.associatedDoc.Evergreen = Number(this.LO.Evergreen);
      this.docAssociationDialog = true;
    },

    filterByStatus(value, search, item) {
      if (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      ) {
        if (this.docsSearchFilter == "All") return true;
        else return item.CurrentStatus == this.docsSearchFilter;
      } else return false;
    },

    featureDelete(item, action){
      var r = confirm("Are you sure you want to remove the association with this doc?  This will remove the data relationship.  It is preferable to expire your doc.");
      if (r != true) {
        return;
      } else {
        this.deleteOverlay = true;
        let url = action == "expired" ? '/directory/lineorder/featureExpiredDelete' : '/directory/lineorder/featureDelete';

        this.axios
        .put(url, { associatedDoc: item })
        .then((res) => {
          console.log(res.data);
          this.deleteOverlay = false;
          this.$store.commit("sendAlert", {
            msg: action.charAt(0).toUpperCase() + action.slice(1) + " Document Association Successfully Deleted",
            color: "success"
          });
        })
        .catch((error) => {
          this.deleteOverlay = false;
          this.$store.commit("sendAlert", {
            msg: "Error Deleting Document Association",
            color: "error"
          });
          console.log(error);
        })
        .finally(() => {
          this.getAllDocs();
        })
      }
    },

    featureInsert(associatedDoc){
      this.axios
        .put('/directory/lineorder/featureInsert', {
          associatedDoc: associatedDoc
        })
        .then((res) => {
          console.log(res.data);
          this.overlay = false;
          this.$store.commit("sendAlert", {
            msg: "Document Association Successfully Added",
            color: "success"
          });
        })
        .catch((error) => {
          this.overlay = false;
          this.$store.commit("sendAlert", {
            msg: "Error Adding Document Association",
            color: "error"
          });
          console.log(error);
        })
        .finally(() => {
          this.getAllDocs();
          this.docAssociationDialog = false;
          this.associatedDocError = null;
          this.associatedDoc = null;
          this.saving = false;
        })
    },

    featureUpdate(associatedDoc){
      this.axios
        .put('/directory/lineorder/featureUpdate', {
          associatedDoc: associatedDoc
        })
        .then((res) => {
          console.log(res.data);
          this.overlay = false;
          this.$store.commit("sendAlert", {
            msg: "Document Association Successfully Updated",
            color: "success"
          });
        })
        .catch((error) => {
          this.overlay = false;
          this.$store.commit("sendAlert", {
            msg: "Error Updating Document Association",
            color: "error"
          });
          console.log(error);
        })
        .finally(() => {
          this.editAssociation = false;
          this.docAssociationDialog = false;
          this.associatedDocError = null;
          this.associatedDoc = null;
          this.associatedDocOrig = null;
          this.saving = false;
        })
    },

    isExpired(item) {
      return item.CurrentStatus == "Expired" ? "expired" : "";
    },

    resetAssociatedDates(){
      let docIndex = this.allDocs.findIndex((x) => x.DocId == this.associatedDocOrig.DocId);

      if(docIndex != -1){
        this.allDocs[docIndex].StartDate = this.associatedDocOrig.StartDate;
        this.allDocs[docIndex].EndDate = this.associatedDocOrig.EndDate;
      }
    },

    saveDocAssociation(){
      this.associatedDocError = "";
      this.saving = true;
      this.overlay = true;

      //Extra validation to ensure that the associatedDoc dates are only validated against the last saved LON dates
      if(this.origLO.StartDate > this.associatedDoc.StartDate)
        this.associatedDocError = `Association Start Date Can't Be Before The CurrentlySaved LON Start Date: [${this.origLO.StartDate}]<br>`;

      if(this.origLO.EndDate < this.associatedDoc.EndDate)
        this.associatedDocError += `Association End Date [${ this.associatedDoc.EndDate}] Can't Be After The Currently Saved LON End Date: [${this.origLO.EndDate}]<br>`;

      if(this.associatedDocError.length != 0){
        this.resetAssociatedDates();
        this.saving = false;
        this.overlay = false;
        return;
      }

      //if updating
      if(this.editAssociation){
        let msg = "";

        //Verify that dates don't conflict with any other LO feature dates for given document
        this.axios
        .put('/directory/lineorder/checkFeatureDates', {
          associatedDoc: this.associatedDoc
        })
        .then((res) => {
          msg = res.data.msg;
          //If !error, update, else return with errors
          if(msg.length == 0){
            this.featureUpdate(this.associatedDoc);
          }
          else {
            this.resetAssociatedDates();
            this.associatedDocError = msg;
            this.saving = false;
            this.overlay = false;
            return;
          }
        })
      } //If new association
      else {
        this.featureInsert(this.associatedDoc);
      }
    },

    updateDocHeaders(){
      if(this.docsSearchFilter == "Active"){
        let registrationIndex = this.docHeaders.findIndex((x) => x.text == 'Registration');
        if(registrationIndex != -1) this.docHeaders.splice(registrationIndex, 1);
        Array.prototype.splice.apply(this.docHeaders, [5, 0].concat(this.primaryTopicHeader));
      }
      else if(this.docsSearchFilter == "Expired"){
        let primaryTopicIndex = this.docHeaders.findIndex((x) => x.text == 'Primary Topic');
        if(primaryTopicIndex != -1) this.docHeaders.splice(primaryTopicIndex, 1);
        Array.prototype.splice.apply(this.docHeaders, [5, 0].concat(this.registrationHeader));
      }
    }
  },
  watch: {

    search(val) {
      if (val == null) return;
      if (val.length < 2) return;

      const axiosSource = axios.CancelToken.source();

      if (this.request) this.request.cancel();
      this.request = { cancel: axiosSource.cancel };

      this.isLoading = true;

      this.axios
        .get(`/directory/lineorder/getDocsAutocomplete?id=${val}`, {
          cancelToken: axiosSource.token
        })
        .then((res) => {
          this.request = undefined;
          this.docs = res.data;

          if (this.docs.length == 0) {
            this.$store.commit("sendAlert", {
              msg: "No Search Results",
              color: "error",
              position: "top"
            });
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            // request cancelled
          }
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
<style scoped lang="scss">
/*
  Documents Tab
*/
#docs-row {
  padding-bottom: 10px;

  ::v-deep .v-card__title {
    background: #009688 !important;
    padding: 0 15px 0 !important;

    .searchFilter .v-select__slot {
      .v-label,
      .v-select__selection,
      .v-icon {
        color: white !important;
      }
    }
    .v-text-field__slot {
      .v-label,
      input,
      .v-icon {
        color: white !important;
      }
    }
    .v-input__control {
      .v-input__slot:before {
        border-color: white !important;
      }
      .v-icon {
        color: white !important;
      }
    }
  }
}
::v-deep tr.expired .text-start {
  text-decoration: line-through;
}

::v-deep tr {
  &:nth-of-type(even) {
    background-color: rgba(150, 150, 150, 0.2);
  }
  &:hover {
    background: lighten(gray, 25%) !important;
  }
}
.searchFilter {
  width: 100px;
  max-width: 100px;
}

::v-deep .v-list-item.v-list-item--link.theme--light {
  min-height: 40px;
  &:nth-of-type(odd) {
    background-color: rgba(150, 50, 50, 0.2);
  }
}

::v-deep .v-list-item--disabled {
  background-color: lightgray !important;
  border-bottom: 1px silver solid;
}

::v-deep .strike {
  text-decoration: line-through !important;
}
.add-pointer-event{
  pointer-events: all !important;
}
</style>