<template>
  <div>
    <v-row justify="center" v-if="formLoaded">
      <v-col :cols="newLON ? 4 : 2">
        <v-row justify="end" class="mt-0 mb-0 ml-0 mr-0">Display Ad in DFP</v-row>
        <v-row
          v-if="LO && LO.dfp_orderId"
          justify="end"
          class="mt-0 mb-0 ml-0 mr-0"
        >
          <a :href="`https://www.google.com/dfp/${$store.getters['auth/getApiHost']['dfp_network_id']}#delivery/OrderDetail/orderId=${LO.dfp_orderId}`" target="_blank">
            Order Id: {{ LO.dfp_orderId }}
          </a>
        </v-row>

        <v-row justify="end" class="mt-0 mb-0 ml-0 mr-0">
          <v-switch
            :class="{ 'mt-0 mb-0 ml-0 mr-0': LO && LO.dfp_orderId }"
            inset
            :false-value="0"
            :true-value="1"
            :ref="`input_enableDFP`"
            :value="LO.enableDFP"
            v-model="LO.enableDFP"
            @change="updateValue('enableDFP',$event)"
          ></v-switch>
        </v-row>

        <v-col v-if="formLoaded">
          <div
            v-if="
              !newLON &&
                LO.ProgramType &&
                LO.ProgramType == 'Direct Response/Bypass (no reg required)' &&
                LO.enableDFP &&
                LO.enableDFP == 1"
          >
            <v-row justify="end" class="mt-0 mb-0 ml-0 mr-0">DFP No Redirect</v-row>

            <v-row justify="end" class="mt-0 mb-0 ml-0 mr-0">
              <v-switch
                inset
                :false-value="0"
                :true-value="1"
                :ref="input_noredirect_dfp"
                :value="LO.noredirect_dfp"
                v-model.number="LO.noredirect_dfp"
                @change="updateValue('noredirect_dfp', $event)"
              ></v-switch>
            </v-row>
          </div>
        </v-col>
      </v-col>

      <v-col :cols="newLON ? 4 : 2">
        <!-- TODO: checkout hidden form input in comparrison to the shown field -->
        <v-row justify="center" class="mt-0 mb-0 ml-0 mr-0">Adobe TM Sync</v-row>

        <v-row justify="center" class="mt-0 mb-0 ml-0 mr-0">
          <!-- TODO: update how value works to take into account that value can be 0,1,2-->
          <v-switch
            inset
            :ref="`input_TMSync`"
            :value="LO.TMSync"
            v-model="LO.TMSync"
            @change="updateValue('TMSync',$event)"
          ></v-switch>
        </v-row>
      </v-col>

      <v-col :cols="newLON ? 4 : 2">
           <!-- TODO: checkout hidden form input in comparrison to the shown field -->
          <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">Daily Cap Leads</v-row>

          <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">
            <v-col cols="6" class="d-flex pt-0">
              <v-switch
                inset
                :false-value="0"
                :true-value="1"
                :ref="`input_EnableDailyCap`"
                :value="LO.EnableDailyCap"
                v-model="LO.EnableDailyCap"
                @change="updateValue('EnableDailyCap', $event)"
              >
              </v-switch>
              <div
                class="mt-3"
                v-show="formLoaded && LO.EnableDailyCap && LO.EnableDailyCap == true"
              >
                <InputControl
                  attrId="DailyCap"
                  ref="input_DailyCap"
                  :value="LO.DailyCap"
                  placeholder="Daily Cap"
                  :bind="{ readonly: false }"
                  @update:value="updateValue('DailyCap',$event)"
                />
              </div>
            </v-col>
        </v-row>
      </v-col>

      <v-col cols="3"
        v-show="
          !newLON &&
            formLoaded &&
            LO.ProgramType &&
            LO.ProgramType == 'Lead Capture/Collect Leads'"
          >
        <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">Scoring Topic</v-row>

        <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">
            <v-switch
              inset
              :ref="`input_ScoringTopic`"
              :value="LO.enableScoring"
              v-model="LO.enableScoring"
              @change="updateValue('enableScoring', $event)"
            ></v-switch>

            <div v-if="formLoaded && LO.enableScoring">
              <v-select
                v-model="LO.ScoringTopic"
                :items="topics"
                item-text="TopicName"
                item-value="TopicID"
                required
              >
              </v-select>
            </div>
        </v-row>
      </v-col>

      <v-col v-if="LO.MedusaLink && !newLON" cols="3">
        <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">Promote In Medusa</v-row>

        <v-row v-if="origLO.StartDate > today" justify="start" class="mt-0 mb-0 ml-0 mr-0">
          Not able to promote in MEDUSA due to out of active date range.
        </v-row>

        <div v-else>
          <v-row class="mt-0 mb-0 ml-0 mr-0">
            <span><a :href="LO.MedusaLink" target="_blank">[ View LO in Medusa ]</a> | <a href="#">[ Placement Restriction (0) ]</a></span>
          </v-row>

          <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0 medusaSwitch">
            <v-switch
              :class="{ 'mt-0 mb-0 ml-0 mr-0': LO && LO.dfp_orderId }"
              inset
              :false-value="0"
              :true-value="1"
              :ref="`input_MedusaPromote`"
              :value="LO.MedusaPromote"
              v-model="LO.MedusaPromote"
              @change="updateValue('MedusaPromote',$event)"
            ></v-switch>
          </v-row>

          <v-col v-if="formLoaded" class="pl-0">
            <div v-if="
            !newLON &&
              LO.ProgramType &&
              LO.ProgramType == 'Direct Response/Bypass (no reg required)' &&
              LO.MedusaPromote &&
              LO.MedusaPromote == 1">
              <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">Medusa No Redirect</v-row>

              <v-row justify="start" class="mt-0 mb-0 ml-0 mr-0">
                <v-switch
                  inset
                  :false-value="0"
                  :true-value="1"
                  ref="input_noredirect_medusa"
                  :value="LO.noredirect_medusa"
                  v-model.number="LO.noredirect_medusa"
                  @change="updateValue('noredirect_medusa', $event)"
                ></v-switch>
              </v-row>
            </div>
          </v-col>
        </div>
      </v-col>
      <v-col v-if="!LO.MedusaLink && !newLON" cols="3">Line order does not exist in MEDUSA.</v-col>
    </v-row>
  </div>
</template>

<script>
//import axios from "axios";
import InputControl from "@/components/util/InputControl";

export default {
  props: ["formLoaded", "LO", "onUpdateValue", "newLON", "origLO", "topics","updateValue"],

  components: {
    InputControl
  },

  data: () => ({
    today: null
  }),

  created(){
    this.today = new Date().toISOString().slice(0, 10);
  },
  computed: {
    
  },

  methods: {

  },
  watch: {
  }
};
</script>
<style scoped lang="scss">
  .medusaSwitch .v-input--selection-controls {
    margin-top: 0 !important;
  }
</style>