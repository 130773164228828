<template>
  <div>
    <v-dialog v-model="customQuestionsDialog" scrollable max-width="1200px">

      <template v-slot:activator="{ on }">
        <v-btn
          color="indigo"
          outlined
          :disabled="!formLoaded"
          value="general"
          class="ma-2 text-none"
          v-on="on"
          @click="getCustomQuestions()">

          <v-container>
            <v-row class="mt-0 mb-0 ml-0 mr-0">
              <v-icon large :color="primaryBlue">mdi-message-question</v-icon>
            </v-row>

            <v-row class="mt-0 mb-0 ml-0 mr-0">
              <span class="hidden-sm-and-down">
                Custom Questions<br/>
                {{ LO && LO.QuestionsSet ? LO.QuestionsSet + " Set" : "" }}
              </span>
            </v-row>
          </v-container>
        </v-btn>
      </template>

      <v-card class="customQuestionsDialog">
        <v-app-bar class="card-header">
          <div>
            <v-card-title class="card-header pt-1">Custom Questions</v-card-title>
            <v-card-subtitle class="card-header pb-1">{{LO.LineOrderNumber + ' : ' + LO.LineOrderDesc + (LO.BillingDesc ? ' : ' + LO.BillingDesc : '')}}</v-card-subtitle>
          </div>

          <v-spacer></v-spacer>

          <v-btn color="white" v-if="!loading && questionsSet" @click="setCustomQuestionDialog(true)">
            Add Question <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-app-bar>
        
        <v-divider></v-divider>

        <template v-if="overlay">
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </template>

        <v-container>
          <draggable v-if="!loading && customQuestions.length > 0" v-model="customQuestions" ghost-class="ghost" @end="onQuestionEnd" handle=".handle">
            <transition-group type="transition" name="flip-list">
              <!-- Question data -->
              <v-card v-for="(question, i) in customQuestions" id="question.QuestionOrder" :key="question.QuestionOrder" cols="12" class="questions">
                <v-row class="master-row" no-gutters>

                  <v-col cols="6" class="question">
                    <v-row no-gutters>
                      <v-col cols="2" class="questionIndex" :class="i">
                        <span class="handle"><v-icon>mdi-arrow-up-down-bold-outline</v-icon></span>
                        {{question.QuestionOrder}}
                      </v-col>

                      <v-col cols="10">
                        <ul>
                          <li class="pt-2"><b>{{question.QuestionName}}</b></li>
                          <li><i>Question Style: </i> <span class="ml-2">{{question.QuestionStyle}}</span></li>
                          <li><i>Response Required: </i> <span class="ml-2">{{question.QuestionResponseRequired == 0 ? 'No' : 'Yes'}}</span></li>
                        </ul>
                      </v-col>
                    </v-row>

                    <v-row justify="start" no-gutters>
                      <v-col cols="12" class="text-center">
                        <v-btn class="ma-2" outlined x-small fab color="indigo">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn class="ma-2" outlined x-small fab color="indigo">
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Answer data -->
                  <v-col cols="6" class="answer">

                    <template v-if="question.Answers.length > 0">
                      <v-toolbar flat class="text-center answers-toolbar">
                        <h3 class="pr-2">Answers</h3>
                        <v-btn color="blue" outlined x-small fab  @click="setCustomAnswerDialog(true)">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-toolbar>


                      <draggable v-model="question.Answers" ghost-class="ghost" @end="onAnswerEnd(question.QuestionId)" handle=".handle">
                        <transition-group type="transition" name="flip-list">

                          <v-row cols="12" v-for="answer in question.Answers" :id="answer.AnswerId" :key="answer.AnswerId" no-gutters>

                            <template v-if="answer.AnswerDesc != ''">
                              <v-col cols="10" class="answerDesc pl-2">
                                <span class="handle"><v-icon>mdi-arrow-up-down-bold</v-icon></span>
                                {{answer.AnswerDesc.length >= 70 ? answer.AnswerDesc.slice(0, 70) + '...' : answer.AnswerDesc}}
                              </v-col>

                              <v-col cols="2">
                                <v-row no-gutters>

                                  <v-btn class="" icon x-small>
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>

                                  <v-btn class="" icon x-small>
                                    <v-icon>mdi-trash-can</v-icon>
                                  </v-btn>

                                  <b v-if="answer.Checked != 0">C</b>
                                  <b v-if="answer.FreeText != 0">F</b>
                                </v-row>
                              </v-col>
                            </template>
                          </v-row>

                        </transition-group>
                      </draggable>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small :disabled="question.AnswersReordered == 0">Save Order</v-btn>
                      </v-card-actions>

                    </template>

                    <template v-else>
                      <v-row no-gutters style="height: 100%">
                        <v-col cols="12" align-self="center">
                          <v-toolbar flat class="text-center answers-toolbar">
                            <h3 class="pr-2">Add An Answer</h3>
                            <v-btn color="blue" outlined x-small fab  @click="setCustomAnswerDialog(true)">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-toolbar>
                        </v-col>
                      </v-row>
                    </template>

                </v-col>

              </v-row>
            </v-card>

            </transition-group>
          </draggable>

          <v-row no-gutters v-if="!loading && !questionsSet" style="height: 300px">
            <v-col cols="12" align-self="center">
              <v-toolbar flat class="text-center answers-toolbar">
                <h3 class="pr-2">Add A Question</h3>
                <v-btn color="blue" outlined x-small fab @click="setCustomQuestionDialog(true)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>

          <app-custom-question :questionsSet="questionsSet"></app-custom-question>
          <app-custom-answer></app-custom-answer>

        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="customQuestionsDialog = false;">Close</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import CustomQuestion from "@/components/directory/lineorder/customQuestions/CustomQuestion";
import CustomAnswer from "@/components/directory/lineorder/customQuestions/CustomAnswer";
import { mapMutations } from "vuex";

export default {
  props: ["formLoaded", "LO", "primaryBlue"],

  components: {
    draggable,
    appCustomQuestion: CustomQuestion,
    appCustomAnswer: CustomAnswer,
  },

  data: () => ({
    customQuestions: [],
    customQuestionsDialog: false,
    dialogLoaded: false,
    loading: false,
    overlay: false,
  }),

  computed: {
    customAnswerDialog() {
      return this.$store.state.lineorder.customAnswerDialog;
    },
    customQuestionDialog() {
      return this.$store.state.lineorder.customQuestionDialog;
    },
    questionsSet(){
      return this.customQuestions.length > 0;
    },
  },

  methods: {
    ...mapMutations({
      setCustomAnswerDialog: "lineorder/setCustomAnswerDialog",
      setCustomQuestionDialog: "lineorder/setCustomQuestionDialog",
    }),

    onAnswerEnd(questionId){
      let questionIndex = this.customQuestions.findIndex((q) => q.QuestionId == questionId);

      if(questionIndex != -1){
        let question = this.customQuestions[questionIndex];
        question.AnswersReordered = true;

        for (let [index, answer] of question.Answers.entries()) {
          if(answer.AnswerOrder == index+1)
            answer.AnswerOrderNew = ''
          else
            answer.AnswerOrderNew = index+1;
        }
      }
    },
    onQuestionEnd(){
      for (let [index, question] of this.customQuestions.entries()) {
        if(question.QuestionOrder == index+1)
          question.QuestionOrderNew = '';
        else
          question.QuestionOrderNew = index+1;
      }
    },

    getCustomQuestions() {
      if (!this.dialogLoaded) {
        this.loading = !this.loading;
        this.overlay = !this.overlay;

        this.$axios.get(`/directory/lineorder/getCQs?LON=${this.LO.LineOrderNumber}`)
          .then((res) => {
            this.customQuestions = res.data;
            this.customQuestions.QuestionsReordered = false;
            this.overlay = false;
            this.loading = false;
            //this.dialogLoaded =true;
          })
          .catch((error) => {
            this.overlay = false;
            this.loading = false;

            this.$store.commit("sendAlert", {
              msg: "Error Getting Custom Questions<br>" + error.response.data.error.message,
              color: "error"
            });

            console.log(error.response.status + ": " +  error.response.data.error.message);
          })
          .finally(() => {
          });
      }
    }
  },

};
</script>

<style scoped lang="scss">
  ::v-deep .answers-toolbar .v-toolbar__content{
    display: inline-flex;
  }
  .ghost{
    border-left: 6px solid rgb(0,183,255);
    box-shadow: 10px 10px 5px -1px rgba(0,0,0,.14);
    opacity: .5;
  }
  .sortable-drag{
    opacity: 0;
  }
  .flip-list-move{
    transition: transform 0.5s;
  }
  .card-header{
    background: #005eb6 !important;
    color: white !important;
  }
  .answerDesc{
    font-size: 13px;
  }
  .question{
    border-right: solid 1px rgba(0,0,0,.2);

    .questionIndex{
      color: #903;
      font-size: 55px;
      line-height: 50px;
      padding-top: 5px !important;
      padding-right: 8px !important;
      padding-left: 19px !important;
    }
  }
  .master-row{
    max-width: 100% !important;
    margin: 0;
    margin-bottom: 10px;
  }
  ul {
    list-style: none;
    li {
      padding-bottom: 16px;
    }
  }
  
</style>
