<template>
  <div>
    <v-dialog v-model="$store.state.lineorder.customQuestionDialog" persistent :max-width="700">
      <v-form ref="form" v-model="valid">
            <v-card>
              <v-card-title>
              </v-card-title>

              <v-card-subtitle>
              </v-card-subtitle>
              
              <v-card-text>
                <h1>Add Custom Question</h1>

                <v-container>
                  <v-row class="mt-0 mb-0 ml-0 mr-0" justify="center">
                  </v-row>
                </v-container>

                <small>*indicates required field</small>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="setCustomQuestionDialog(false)">
                  Close
                </v-btn>

                <v-btn
                  color="blue darken-1"
                  text
                  @click="setCustomQuestionDialog(false)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["customQuestion"],

  data: () => ({
    valid: false,
  }),

  computed: {
    customQuestionDialog() {
      return this.$store.state.lineorder.customQuestionDialog;
    },
  },

  methods: {
    ...mapMutations({
      setCustomQuestionDialog: "lineorder/setCustomQuestionDialog",
    }),
  },

};
</script>

<style scoped lang="scss">

</style>
