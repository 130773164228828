<template>
  <div>
    <v-dialog v-model="overDeliveryDialog" scrollable max-width="500px">

      <template v-slot:activator="{ on }">
        <v-btn
          color="indigo"
          outlined
          :disabled="!formLoaded"
          value="general"
          class="ma-2 text-none"
          v-on="on"
          @click="getEditLO()">

          <v-container>
            <v-row class="mt-0 mb-0 ml-0 mr-0">
              <v-icon large :color="primaryBlue">mdi-truck-delivery</v-icon>
            </v-row>
            <v-row class="mt-0 mb-0 ml-0 mr-0">
              <span class="hidden-sm-and-down"
                >Overdelivery<br />{{
                  LO && LO.OverDAmount ? LO.OverDAmount + "%" + (LO.OverDLimit == 1 ? "" : " (Disabled)") : ""
                }}</span
              >
            </v-row>
          </v-container>
        </v-btn>
      </template>

      <v-card class="overDeliveryDialog">
        <v-card-title>Overdelivery</v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pl-0 pr-0 pb-0">
          <template v-if="overlay">
            <v-overlay :value="overlay">
              <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
          </template>

          <template>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-radio-group v-model="item.limitDelivery" row @change="checkLimitDelivery">
                  <v-radio label="Limit Overdelivery" :value="1"></v-radio>
                  <v-radio label="Deliver All Leads" :value="0"></v-radio>
                </v-radio-group>

                <v-slider
                  class="pt-0 mb-2 limitSlider"
                  :disabled="item.limitDelivery == 0"
                  :thumb-size="24"
                  thumb-label="always"
                  v-model="item.overDAmount"
                  :max="100">

                  <template v-slot:label>
                    Overdelivery Percentage <span v-if="item.limitDelivery == 1" class="red--text"><strong>* </strong></span>
                  </template>

                  <template v-slot:append>
                    <v-text-field
                      v-model="item.overDAmount"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px">
                    </v-text-field>
                  </template>

                </v-slider>

                <v-textarea v-if="editLO"
                  v-show="item.limitDelivery == 1"
                  auto-grow
                  clearable
                  class="pb-0 preserve-whitespace"
                  counter
                  name="input-7-4"
                  outlined
                  :rules="item.limitDelivery == 1 ? [rules.required, item.overDDesc && item.overDDesc.length > 0 ? rules.min5chars : ''] : []"
                  v-model="item.overDDesc">

                 <template #label>
                    Reason For Change <span class="red--text"><strong>* </strong></span>
                  </template>

                </v-textarea>

              </v-container>
            </v-form>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="overDeliveryDialog = false;">Close</v-btn>
          <v-btn
            color="blue darken-1"
            :disabled="!valid || saving"
            text
            @click="limitDeliveryUpdate()">
            Save
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: ["formLoaded", "LO", "primaryBlue"],

  data: () => ({
    defaultItem: {
      LineOrderNumber: null,
      limitDelivery: 0,
      overDAmount: 2,
      overDDesc: "",
    },
    dialogOpened: false,
    editLO: null,
    item: {
      LineOrderNumber: null,
      limitDelivery: 0,
      overDAmount: 2,
      overDDesc: "",
    },
    overDeliveryDialog: false,
    overlay: false,
    rules: {
      min5chars: (v) => v.length >= 5 || "Reason Must Be Longer Than 5 Characters!",
      required: (v) => !!v || 'Required',
    },
    saving: false,
    valid: false,
  }),

  methods: {
    checkLimitDelivery(){
      if(this.item.limitDelivery == 0)
        this.item.overDAmount = 100;
      else
        this.item.overDAmount = this.editLO.OverDAmount ? this.editLO.OverDAmount : this.item.overDAmount;
    },

    getEditLO(){
      if(this.dialogOpened) this.resetForm();

      this.dialogOpened = true;
      this.editLO = JSON.parse(JSON.stringify(this.LO));

      if(this.editLO.OverDLimit) this.item.limitDelivery = 1;
      if(this.editLO.OverDAmount) this.item.overDAmount = this.editLO.OverDAmount;
      this.item.overDDesc = this.editLO.OverDDesc;
      this.item.LineOrderNumber = this.editLO.LineOrderNumber;
    },

    limitDeliveryUpdate(){
      this.saving = true;
      this.overlay = true;
      this.axios
        .put('/directory/lineorder/limitDeliveryUpdate', {
          LO: this.item
        })
        .then((res) => {
          console.log(res.data);
          this.saving = false;
          this.overlay = false;
          this.$store.commit("sendAlert", {
            msg: "Overdelivery Successfully Updated",
            color: "success"
          });
        })
        .catch((error) => {
          this.saving = false;
          this.overlay = false;
          this.$store.commit("sendAlert", {
            msg: "Error Updating Overdelivery",
            color: "error"
          });
          console.log(error);
        })
        .finally(() => {
          this.LO.OverDLimit = this.item.limitDelivery;
          this.LO.OverDAmount = this.item.overDAmount;
          this.LO.OverDDesc = this.item.overDDesc;
          this.overDeliveryDialog = false;
        })
    },

    resetForm () {
      this.item = JSON.parse(JSON.stringify(this.defaultItem));
      this.$refs.form.resetValidation();
    },
  }
};
</script>

<style scoped lang="scss">
  .overDeliveryDialog .container{
    height: 320px !important;
  }
  ::v-deep .limitSlider > .v-input__control:first-child{
    .v-input__slot{
      padding-top:  20px !important;
    }
  }
  .overDeliveryDialog{
    height: 435px !important;
  }
</style>
